@import "./variables.scss";

@mixin desktop-only {
  @media (min-width: #{map-get($breakpoints, md)}) {
    @content;
  }
}

@mixin mobile-only {
  $width: map-get($breakpoints, md) - 1px;
  @media screen and (max-width: $width) {
    @content;
  }
}

@mixin input-border() {
  border: 1px solid var(--ion-color-light);
}

@mixin input-border-radius($important: false) {
  @if $important {
    border-radius: var(--input-border-radius) !important;
  } @else {
    border-radius: var(--input-border-radius);
  }
}

@mixin input-error-border($important: false) {
  @if $important {
    border: 1px solid var(--ion-color-danger) !important;
  } @else {
    border: 1px solid var(--ion-color-danger);
  }
}

@mixin input-focused() {
  border: 1px solid var(--ion-color-primary);
}

@mixin has-error() {
  &.ion-invalid.ion-dirty:not(.has-focus),
  &.ion-invalid:not(.ion-untouched):not(.has-focus) {
    @content;
  }
}

@mixin has-focus() {
  &.has-focus,
  &.item-has-focus {
    @content;
  }
}

@mixin visually-hidden() {
  position: absolute !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  border: 0 !important;
}
