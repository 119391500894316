// common datatable colors
$ngx-datatable-background: var(--ion-color-primary-contrast);
$ngx-datatable-box-shadow: none;

// row and cell background colors
$ngx-datatable-default-background: var(--ion-color-primary-contrast);
$ngx-datatable-default-background-hover: var(--ion-color-light);
$ngx-datatable-default-background-focus: var(--ion-color-light);

// background colors for cell selection style
$ngx-datatable-cellselection-background-hover: var(--ion-color-light);
$ngx-datatable-cellselection-background-focus: var(--ion-color-active-light);

// colors for header elements
$datatable-header-cell-background: var(--ion-color-light);
$datatable-header-cell-color: var(--ion-color-dark);
$datatable-header-border-bottom-color: none;
$datatable-header-resize-handle-color: #e0e2e3; //also for border of elements

// colors for table body elements
$datatable-row-detail-background: red;
$datatable-body-cell-color: var(--ion-color-dark);
$datatable-group-header-background: var(--ion-color-light);
$datatable-group-header-border-top-color: #e0e2e3;
$datatable-group-header-border-bottom-color: #e0e2e3;

// background and text colors for selected cell / row
$ngx-datatable-selected-active-background: var(--ion-color-primary-contrast);
$ngx-datatable-selected-active-color: #fff;
$ngx-datatable-selected-active-background-hover: var(--ion-color-light);
$ngx-datatable-selected-active-color-hover: #fff;
$ngx-datatable-selected-active-background-focus: var(--ion-color-light);
$ngx-datatable-selected-active-color-focus: #fff;

// colors for footer elements
$datatable-footer-cell-color: var(--ion-color-dark);
$datatable-pager-color: var(--ion-color-dark);
$datatable-pager-color-hover: var(--ion-color-dark);
$datatable-pager-background-hover: transparent;
$datatable-pager-disabled-color: #e0e2e3;
$datatable-pager-disabled-background: transparent;
$datatable-pager-active-color: var(--ion-color-dark);
$datatable-pager-active-background: transparent;

// colors for summary row elements
$datatable-summary-row-background: var(--ion-color-primary-contrast);
$datatable-summary-row-background-hover: var(--ion-color-active-light);
