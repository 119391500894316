/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
@import "./theme/mixins.scss";
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* Ngx-datatable */
@import "~@swimlane/ngx-datatable/themes/material.scss";
@import "~@swimlane/ngx-datatable/assets/icons.css";
@import "~@swimlane/ngx-datatable/themes/dark.scss";
@import "~@swimlane/ngx-datatable/themes/bootstrap.scss";
@import "./theme/ngx-datatable-theme.scss";

/* App */
@import "./theme/functions";
@import "./theme/common/typography";
@import "theme/common/utilities";
@import "./theme/overrides/buttons";
@import "./theme/overrides/inputs";
@import "./theme/overrides/modals";
@import "./theme/overrides/searchbar.scss";
@import "./theme/overrides/alerts.scss";

* {
  font-family: "Inter", sans-serif;
  font-size: var(--font-base);
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-transform: none;
  --inner-border-width: 0;
  --highlight-background: transparent;
}

ion-label {
  padding: 0;
}
.item-has-focus {
  --highlight-background: var(--ion-color-primary);
  --border-width: 0;
  --border-color: var(--ion-color-primary);
  --border-radius: 12px;
}
.item-interactive {
  --border-width: 1px;
  --border-radius: 8px;
}
.background-img {
  position: absolute;
  top: 0;
  right: 0;
  pointer-events: none;
  z-index: -100;
}
.header-ios ion-toolbar:last-of-type {
  --border-width: 0;
}

.horizontal-scroll {
  position: sticky;
  top: 0;
  display: flex;
  z-index: 100;
  flex-wrap: nowrap;
  padding: 20px 0;
  overflow-x: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}
.item-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.scroll-item {
  height: 40px;
  text-transform: none;
  flex: none;
  font-size: 16px;
  --color: var(--ion-color-white);
  background-color: transparent;
  border-radius: 6px;
  --padding-top: 15px;
  --padding-bottom: 15px;
  --padding-end: 15px;
  --padding-start: 15px;
  margin-left: 10px;
  @include desktop-only {
    margin-right: 60px;
  }
}

.active {
  --background: var(--ion-color-white);
  color: var(--ion-color-primary);
  font-weight: bold;
}

.select-alert .alert-wrapper {
  --min-width: min(400px, 90%);
}

// fix for ion-select with Ionic 5 and Chrome 114
ion-popover [popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}
