@import "/src/theme/mixins";

ion-alert.custom-alert {
  --min-width: 500px;
}

.custom-alert .alert-button-group {
  padding: 8px;
}

button.alert-button.alert-button-cancel {
  background-color: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
  border-radius: 8px;
}

button.alert-button.alert-button-confirm {
  background-color: var(--ion-color-light);
  color: var(--ion-color-light-contrast);
  border-radius: 8px;
}
