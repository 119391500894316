@import "../mixins";

//
// General theming and overriding of basic ionic
// input components.
// -----------------------------------------------
//

.t-app {
  ion-input,
  ion-textarea,
  ion-select {
    font-size: var(--font-sm);
    border-radius: 8px;
    --border-width: 1px;
    --placeholder-color: var(--ion-color-medium);
    --placeholder-opacity: 1;
    --padding-bottom: 1rem;
    --padding-end: 1rem;
    --padding-start: 1rem;
    --padding-top: 1rem;
    overflow: hidden;

    @include has-error {
      border-color: var(--ion-color-danger);
      --placeholder-color: var(--ion-color-danger);
    }

    &:focus-visible {
      border-color: var(--ion-color-primary);
    }
  }

  ion-textarea {
    margin-top: 0;
  }

  ion-textarea .textarea-wrapper {
    font-size: var(--font-sm);
  }

  ion-item {
    --background-focused: var(--ion-color-light);
    --background-focused-opacity: 0.5;
    --background-activated: var(--ion-color-primary);
    .item-has-focus {
      --border-color: var(--ion-color-primary);
    }
    @include has-error {
      ion-label {
        color: var(--ion-color-danger);
      }
    }
  }

  .item-label-stacked ion-label {
    font-weight: 600;
  }

  ion-label.required::after {
    content: "*";
    color: #fe0900;
    font-size: 16px;
    line-height: 0;
    top: 3px;
    position: relative;
    margin-left: 3px;
  }

  ion-select {
    &::part(text),
    &::part(placeholder) {
      padding-right: 20px;
    }

    &::part(icon) {
      position: absolute;
      right: 1rem;
    }

    @include has-error {
      &::part(icon) {
        opacity: 0.4;
      }
    }
  }

  ion-select-popover ion-label {
    font-size: var(--font-sm);
  }

  ion-input.search-input {
    --background: var(--ion-color-light);
    --padding-bottom: 9px;
    --padding-top: 9px;
    --padding-start: 12px;
    border-radius: 36px;
    &:before {
      content: "";
      width: 16px;
      height: 16px;
      background-image: url("/assets/icon/search.svg");
      margin-left: 14px;
      position: relative;
    }
  }

  ion-checkbox {
    --border-color: var(--ion-color-input-border);
  }

  ion-toggle {
    --background: var(--ion-color-input-border);
  }
  ion-radio-group ion-item {
    --border-color: var(--ion-color-input-border);
  }

  .form-label {
    line-height: 12px !important;
    font-weight: 600;
    margin-bottom: 8px;
  }

  .form-item {
    --inner-border-width: 0;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
