/* below styling get weird and seems messy,
 but in order to achive wanted design with this library that was the only way I found it working */
.ngx-datatable.material {
  padding-top: 1.25rem;
  width: 100%;

  @include desktop-only {
    max-width: 90%;
    margin: 0 auto;
  }

  .datatable-icon-sort-unset::before {
    content: "\66";
    opacity: 0.5;
  }
  datatable-header * {
    font-size: var(--font-sm);
    font-weight: 600;
  }
  datatable-header.datatable-header {
    border: 1px solid transparent;
    border-radius: 12px;
    display: flex;
    align-items: center;
  }
  datatable-body * {
    font-size: var(--font-sm);
    font-weight: 400;
  }
  datatable-body {
    min-height: 500px;
    datatable-body-row {
      cursor: pointer;
      border-bottom: $datatable-group-header-border-bottom-color solid 1px;
      .datatable-body-cell {
        display: flex;
        align-items: center;
      }
    }
  }
  /* bg-color on hover isn't working as expected, I'll keep trying to figure it out */
  // functionality currently disabled in the overview component
  // .row-unread-color {
  //   background-color: var(--ion-color-active-light);
  //   border-radius: 12px;
  //   &:hover {
  //     background-color: var(--ion-color-active-light-hover) !important;
  //   }
  // }

  datatable-footer * {
    font-size: var(--font-xs);
  }
  .datatable-footer {
    border-top: none;
    color: var(--ion-table-footer);
    .datatable-pager {
      margin: 0;
      .pager li a {
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        i {
          display: flex;
          align-items: center;
        }
      }
    }
  }
  datatable-footer {
    width: 100%;
    .datatable-footer-inner {
      font-size: var(--font-xs);
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: nowrap;
    }
    .datatable-footer {
      .page-count {
        line-height: 50px;
        height: 50px;
        padding: 0;
      }

      .datatable-pager {
        margin: 0;
        flex: 1 1 40%;

        li {
          padding: 0;
        }
      }
    }

    .datatable-icon-right {
      padding: 0.4rem 1rem !important;
      border: #e0e2e3 solid 1px !important;
      border-radius: 0 12px 12px 0;
    }

    .datatable-icon-left {
      padding: 0.4rem 1rem !important;
      border: #e0e2e3 solid 1px !important;
      border-radius: 12px 0 0 12px;
    }

    ul.pager > li:first-child,
    ul.pager > li:last-child,
    ul.pager > li.pages {
      display: none;
    }
  }
}
/*  Below really ugly solution to round record's background border on hover and while active.
    For some reason it didn't work with cleaner solution.
*/
.ngx-datatable.material {
  .datatable-body-row:hover {
    border-radius: 12px;
    .datatable-row-group {
      border-radius: 12px;
      .datatable-row.active:hover {
        border-radius: 12px;
      }
    }
  }
  .datatable-body-row.active {
    border-radius: 12px;
    .datatable-body-row.active:hover {
      border-radius: 12px;
    }
    .datatable-row-group {
      border-radius: 12px;
      .datatable-row.active:hover {
        border-radius: 12px;
      }
    }
  }
}
