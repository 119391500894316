@import "../mixins";

ion-searchbar.searchbar {
  --background: var(--ion-color-white);
  --color: var(--ion-color-searchbar-color);
  --placeholder-color: var(--ion-color-searchbar-placeholder);
  --placeholder-opacity: 1;
  --icon-color: var(--ion-color-searchbar-color);
  --clear-button-color: var(--ion-color-searchbar-clear-button-color);
  --box-shadow: none;
  border-radius: 8px;
  border: 1px solid var(--ion-item-border-color);
  padding: 3px;
}

ion-searchbar {
  .searchbar-input-container {
    input.searchbar-input {
      font-size: var(--font-sm) !important;
      padding-inline-start: 45px;
    }
    .searchbar-search-icon {
      left: 12px;
    }
  }
}
