// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

//NGX-DATATABLE
@import "./ngx-datatable-variables.scss";
@import "./functions";

$breakpoints: (
  xs: 480px,
  md: 768px,
  lg: 1024px,
  xl: 1200px,
);

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #2c59c8;
  --ion-color-primary-rgb: 44, 89, 200;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #274eb0;
  --ion-color-primary-tint: #416ace;

  --ion-color-background-dark: #acacad;
  --ion-item-border-color: #e0e2e3;
  --ion-detail-page-border-color: #e1e3e4;
  --ion-font-family: "Inter", sans-serif;
  --ion-table-footer: #92929f;
  --placeholder-color: red;
  /** secondary **/
  --ion-color-secondary: #2c59c8;
  --ion-color-secondary-rgb: 44, 89, 200;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #274eb0;
  --ion-color-secondary-tint: #416ace;
  --ion-color-secondary-btn: #f5f6f8;

  --ion-background-color: #fff;
  /** tertiary **/
  --ion-color-tertiary: #2c59c8;
  --ion-color-tertiary-rgb: 44, 89, 200;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #274eb0;
  --ion-color-tertiary-tint: #416ace;

  --ion-card-border-bottom: 1px solid #b9c7d9;
  --ion-color-gray: #e0e0e0;
  --ion-color-black: #000000;
  --ion-color-dark-grey: #68687a;
  --ion-color-white: #ffffff;
  --ion-color-sky: #7b9db3;
  /** success **/
  --ion-color-success: #0d6759;
  --ion-color-success-rgb: 13, 103, 89;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0b5b4e;
  --ion-color-success-tint: #25766a;

  /** warning **/
  --ion-color-warning: #a26749;
  --ion-color-warning-rgb: 162, 103, 73;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #8f5b40;
  --ion-color-warning-tint: #ab765b;

  /** danger **/
  --ion-color-danger: #cd2026;
  --ion-color-danger-rgb: 205, 32, 38;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #b41c21;
  --ion-color-danger-tint: #d2363c;

  /** dark **/
  --ion-color-dark: #424242;
  --ion-color-dark-rgb: 27, 33, 30;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #181d1a;
  --ion-color-dark-tint: #2e2c56;

  /** medium **/
  --ion-color-medium: #68687a;
  --ion-color-medium-rgb: 104, 104, 122;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #5c5c6b;
  --ion-color-medium-tint: #777787;

  /** light **/
  --ion-color-light: #f5f6f8;
  --ion-color-light-rgb: 255, 255, 255;
  --ion-color-light-contrast: #1b211e;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d8d8da;
  --ion-color-light-tint: #f6f7f9;
}

/** Custom CSS variables **/
:root {
  /** Color **/
  --ion-color-lightblue: #eeeff1;
  --ion-color-darkblue: #4a4c69;
  --ion-color-danger-light: #fae9e9;
  --ion-color-danger-rgba: rgba(205, 32, 38, 0.2);
  --ion-color-white: #ffffff;
  --ion-color-pattensblue: #e0e2e3;
  --ion-color-disabled: #88889c;
  --ion-color-border-light: #e4e5ea;
  --ion-color-rose: #ff3144;

  --ion-color-active: #2c59c8;
  --ion-color-active-light: #f5f6fc;
  --ion-color-active-light-hover: #ecf0fa;
  --ion-color-active-rgb: 44, 89, 200;
  --ion-color-active-contrast: #ffffff;
  --ion-color-active-contrast-rgb: 255, 255, 255;
  --ion-color-active-shade: #274eb0;
  --ion-color-active-tint: #416ace;

  --ion-color-status-approved: #0d6759;
  --ion-color-status-approved-rgb: 13, 103, 89;
  --ion-color-status-approved-contrast: #ffffff;
  --ion-color-status-approved-contrast-rgb: 255, 255, 255;
  --ion-color-status-approved-shade: #0b5b4e;
  --ion-color-status-approved-tint: #25766a;

  --ion-color-status-cancelled: #cd2026;
  --ion-color-status-cancelled-rgb: 205, 32, 38;
  --ion-color-status-cancelled-contrast: #ffffff;
  --ion-color-status-cancelled-contrast-rgb: 255, 255, 255;
  --ion-color-status-cancelled-shade: #b41c21;
  --ion-color-status-cancelled-tint: #d2363c;

  --ion-color-status-pending: #a26749;
  --ion-color-status-pending-rgb: 162, 103, 73;
  --ion-color-status-pending-contrast: #ffffff;
  --ion-color-status-pending-contrast-rgb: 255, 255, 255;
  --ion-color-status-pending-shade: #8f5b40;
  --ion-color-status-pending-tint: #ab765b;

  --ion-color-status-completed: #68687a;
  --ion-color-status-completed-rgb: 104, 104, 122;
  --ion-color-status-completed-contrast: #ffffff;
  --ion-color-status-completed-contrast-rgb: 255, 255, 255;
  --ion-color-status-completed-shade: #5c5c6b;
  --ion-color-status-completed-tint: #777787;

  --ion-color-searchbar-placeholder: #9ca5b0;
  --ion-color-searchbar-color: #9ca5b0;
  --ion-color-searchbar-clear-button-color: #9ca5b0;

  // Changed by js based on user.high_contrast
  --ion-color-input-border: var(--ion-color-pattensblue);

  --ion-toolbar-background: var(--ion-color-white);
  --ion-tab-bar-background: var(--ion-color-white);

  --ion-safe-area-bottom-half: calc(var(--ion-safe-area-bottom) / 2);
  --ion-padding: 1rem;

  /** Border-radius **/
  --ion-border-radius: 12px;

  /** Font sizes **/
  --font-base: #{to-rem(16px)};
  --font-xxs: #{to-rem(12px)};
  --font-xs: #{to-rem(13px)};
  --font-sm: #{to-rem(14px)};
  --font-md: #{to-rem(18px)};
  --font-lg: #{to-rem(20px)};
  --font-xl: #{to-rem(24px)};
  --font-xxl: #{to-rem(28px)};
  --font-xxxl: #{to-rem(36px)};

  /** Font line height **/
  --line-height-base: #{to-rem(24px)};
  --line-height-xxs: #{to-rem(20px)};
  --line-height-xs: #{to-rem(20px)};
  --line-height-sm: #{to-rem(22px)};
  --line-height-md: #{to-rem(27px)};
  --line-height-lg: #{to-rem(28px)};
  --line-height-xl: #{to-rem(30px)};
  --line-height-xxl: #{to-rem(35px)};
  --line-height-xxxl: #{to-rem(48px)};
}
