@import "../mixins";

//
// Utilities
// ---------
//
// Our main source of utility classes is Ionic -> https://ionicframework.com/docs/layout/css-utilities
// But because class set is rather small, this file compliments it with additional classes.
//
//

//
// Spacing
// -------
//
$spacer: 0.25rem; // 4px
$steps: 11; // from 0px through 4, 8, etc up to 10 eg 40px (2.5rem)
$spacing-utils: (
  p: (
    "padding-top",
    "padding-right",
    "padding-bottom",
    "padding-left",
  ),
  pt: (
    "padding-top",
  ),
  pr: (
    "padding-right",
  ),
  pb: (
    "padding-bottom",
  ),
  pl: (
    "padding-left",
  ),
  px: (
    "padding-left",
    "padding-right",
  ),
  py: (
    "padding-top",
    "padding-bottom",
  ),
  m: (
    "margin-top",
    "margin-right",
    "margin-bottom",
    "margin-left",
  ),
  mt: (
    "margin-top",
  ),
  mr: (
    "margin-right",
  ),
  mb: (
    "margin-bottom",
  ),
  ml: (
    "margin-left",
  ),
  mx: (
    "margin-left",
    "margin-right",
  ),
  my: (
    "margin-top",
    "margin-bottom",
  ),
);

// Example: pt-4, mb-2, p-0, pt-sm-2, mb-lg-4
@each $selector, $props in $spacing-utils {
  .#{$selector} {
    @each $br, $width in $breakpoints {
      @if $br == xs {
        @for $step from 0 to $steps {
          &-#{$step} {
            @each $property in $props {
              #{$property}: $step * $spacer !important;
            }
          }
        }
      }
    }
  }
}

.mt-auto {
  margin-top: auto !important;
}
.mr-auto {
  margin-right: auto !important;
}
.mb-auto {
  margin-bottom: auto !important;
}
.ml-auto {
  margin-left: auto !important;
}
.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}
.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

//
// Display
// -------
//
$displays: flex, block, inline, inline-block, none;

// Example: d-flex
@each $selector in $displays {
  .d-#{$selector} {
    display: $selector !important;
  }
}

//
// Flex system
// -----------
//
$justify-contents: (
  baseline: baseline,
  center: center,
  start: flex-start,
  end: flex-end,
  space-around: space-around,
  space-between: space-between,
  space-evenly: space-evenly,
);

// Example: flex--center, flex--start, flex--space-evenly
@each $selector, $value in $justify-contents {
  .flex--#{$selector} {
    display: flex;
    justify-content: $value;
    align-items: center;
  }
}

.flex-1 {
  flex: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

// Font weights
.text--thin {
  font-weight: 100 !important;
}
.text--light {
  font-weight: 300 !important;
}
.text--normal {
  font-weight: 400 !important;
}
.text--medium {
  font-weight: 500 !important;
}
.text--semibold {
  font-weight: 600 !important;
}
.text--bold {
  font-weight: 700 !important;
}

// Font sizes, line heights, text
$sizes: base, xxs, xs, sm, md, lg, xl, xxl;

// Example: text--xs, text--lg
@each $size in $sizes {
  .text--#{$size} {
    font-size: var(--font-#{$size}) !important;
    line-height: var(--line-height-#{$size}) !important;
  }
}

// Example: font--base, font--xl
@each $size in $sizes {
  .font--#{$size} {
    font-size: var(--font-#{$size}) !important;
  }
}

.base-paragraph {
  font-size: var(--font-base);
  line-height: var(--line-height-base);
  font-weight: 300;
  color: var(--ion-color-dark);
}

//
// Colors and backgrounds
// ----------------------
//
$colors: primary, secondary, tertiary, success, warning, danger, dark, medium,
  light, active, "status-approved", "status-cancelled", "status-pending",
  "status-completed", "light-shade", "lightblue", "danger-light", "greylight",
  "white", "pattensblue", "darkblue", "disabled", "lightgray";

// Example: color--primary, color--danger, background--white
@each $color in $colors {
  .color--#{$color} {
    color: var(--ion-color-#{$color}) !important;
  }

  .color--#{$color}--contrast {
    color: var(--ion-color-#{$color}-contrast) !important;
  }

  .background--#{$color} {
    background-color: var(--ion-color-#{$color}) !important;
    --background: var(--ion-color-#{$color}) !important;
    background: var(--ion-color-#{$color}) !important;
  }

  .background--#{$color}-normal {
    background-color: var(--ion-color-#{$color});
    --background: var(--ion-color-#{$color});
    background: var(--ion-color-#{$color});
  }
}

.background--none {
  background: none !important;
  --background: none !important;
}

.box-shadow--none {
  box-shadow: none;
}

//
// Misc
// ----
//
.h-100 {
  height: 100%;
}

.mh-100 {
  min-height: 100%;
}

.h-auto {
  height: auto;
}

.w-100 {
  width: 100%;
}

.border {
  border: 1px var(--ion-color-lightblue) solid;
  border-radius: 12px;
}

.border-bottom {
  border-bottom: 1px var(--ion-color-lightblue) solid;
  &.border-pattensblue {
    border-color: var(--ion-color-pattensblue);
  }
}

.border-none {
  border: none;
  --border-width: 0 !important;
  --border: none;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &--2 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    white-space: normal;
  }
  &--3 {
    @extend .text-ellipsis--2;
    -webkit-line-clamp: 3;
  }
}

.disabled-selector.item-disabled {
  opacity: 1;
  fl-select {
    opacity: 0.5;
  }
}

/**
* A11y (from bootstrap)
**/
.visually-hidden {
  @include visually-hidden();
}
